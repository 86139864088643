import Constants from '../utils/Constants';
import ServiceBase from './ServiceBase';

export default class AuthoriseService extends ServiceBase {

    /**
     * 
     */
    authorise = (userName, password) => {

      let url = Constants.ServiceAPI.authenticateUrl;

      let packet = this.buildRequestPacket(Constants.Http.Method.POST, {"username": userName, "password": password} );

      return fetch(url, packet).then(this.convertResponseToJson).then(json => {
        return json;
      });
    }

   
}