import React, { useEffect, useState } from 'react';
import ServiceAPI from '../../../service/ServiceAPI';
import { useParams } from 'react-router-dom';
import CompletionRecordSelectionView from './CompletionRecordSelectionView';
import LockerSelectionView from './LockerSelectionView';
import LockerContentView from './LockerContentView';
import LockerItemView from './LockerItemView';
import InventoryModel from '../../../model/InventoryModel';

const Views = {
  CompletionRecordSelectionView: 1,
  MemberSelectionView: 2,
  LockerSelectionView: 3,
  LockerContentView: 4,
  LockerItemView: 5
};

const CompleteInventoryWorkflow = function(props) {
  const params = useParams();

  const [currentView, setCurrentView] = useState(Views.CompletionRecordSelectionView);
  const [inventoryId, setInventoryId] = useState(null);
  const [completionRecords, setCompletionRecords] = useState(null); // All the completion records for the inventory
  const [completionRecord, setCompletionRecord] = useState(null);   // The selected completion record to use
  const [inventoryData, setInventoryData] = useState(null);
  const [currentLocker, setCurrentLocker] = useState(null);
  const [currentItem, setCurrentItem] = useState(null);
  const [inventoryTranscriptUpdated, setInventoryTranscriptUpdated] = useState(null);

  /**
   * Load required inventory data for the workflow.
   */
  useEffect(() => {
    if (inventoryData !== null) {
      return;
    }

    // Set the selected inventory id from the passed url params.
    setInventoryId(params.inventoryId);

    // Get the current completion records 
    ServiceAPI.CompletionRecord().getCompletionRecordListing(params.inventoryId).then(
      (data) => {
        setCompletionRecords(data);
        console.log("Completion Records", data);
      }
    );

    // Get the inventory data for the selected inventory.
    ServiceAPI.Inventory().read(params.inventoryId).then(
      (data) => {
        console.debug("InventroyData from ServiceAPI", data);
        
        let inventoryModel = new InventoryModel( data );
        setInventoryData(inventoryModel);
      }
    )
  }, []);

  /**
   * Cleans up the state as the user moves around the workflow
   */
  useEffect(() => {

    switch (currentView) {
      case Views.CompletionRecordSelectionView:
        console.debug("Clearing state for: CompletionRecord, CurrentLocker, CurrentItem");
        setCompletionRecord(null);
        setCurrentLocker(null);
        setCurrentItem(null);
        break;

      // 2. Select members that are helping 
      // TODO: future feature

      // 3. Show Lockers
      case Views.LockerSelectionView:
        console.debug("Clearing state for: CurrentLocker, CurrentItem");
        setCurrentLocker(null);
        setCurrentItem(null);
        break;

      // 4. Show Locker Content
      case Views.LockerContentView:
        console.debug("Clearing state for: CurrentItem");
        setCurrentItem(null);
        break;

      // 5. Show Locker Item Content
      case Views.LockerItemView:
        console.debug("At LockerItem view, no state to clear.");
        // Nothing to do at the moment
        break;
    }

  }, [currentView]);

  /**
   * Render
   */
  const render = function() {
    let jsx = <h1>Loading...</h1>;

    switch (currentView) {
      
      // 1. User selects Completion Record to use
      case Views.CompletionRecordSelectionView:
        jsx = renderCompletionRecordSelection();
        break;

      // 2. Select members that are helping 
      // TODO: future feature

      // 3. Show Lockers
      case Views.LockerSelectionView:
        jsx = renderLockerSelectionView();
        break;

      // 4. Show Locker Content
      case Views.LockerContentView:
        jsx = renderLockerContentView();
        break;

      // 5. Show Locker Item Content
      case Views.LockerItemView:
        jsx = renderLockerItemView();
        break;
    }

    return jsx;
  }

  /**
   * Checks the variable state to confirm if the Completion Record Selection screen
   * should be shown, and if so returns it.
   * @returns 
   */
  const renderCompletionRecordSelection = function() {
    if (completionRecords === null) {
      return null;
    }

    // Check that a completion record hasnt already been selected
    // if one has, we dont render anything!
    if (completionRecord !== null) {
      return null;
    }

    return (
      <CompletionRecordSelectionView 
        workflowViews={Views} 
        setCurrentView={setCurrentView}
        existingCompletionRecords={completionRecords} 
        inventoryId={inventoryId}
        handleSelectedCompletionRecord={setCompletionRecord}
      />
    );
  }

  /**
   * Checks the variable state to confirm if the Locker Selection View should 
   * be shown, and if so returns it.
   * @returns 
   */
  const renderLockerSelectionView = function() {
    if (completionRecord === null || inventoryData === null) {
      return null;
    }

    return (
      <LockerSelectionView 
        workflowViews={Views} 
        setCurrentView={setCurrentView} 
        inventoryData={inventoryData} 
        handleSelectedLocker={setCurrentLocker} 
        completionRecord={completionRecord}
      />
    );
  }

  /**
   * Checks the variable state to confirm if the Locker Content View should 
   * be shown, and if so returns it.
   */
  const renderLockerContentView = () => {
    if (currentLocker === null || completionRecord === null || inventoryData === null) {
      return null;
    }

    return (
      <LockerContentView 
        workflowViews={Views} 
        setCurrentView={setCurrentView}
        setInventoryTranscriptUpdated={setInventoryTranscriptUpdated}
        completionRecord={completionRecord} 
        setCompletionRecord={setCompletionRecord}
        selectedLocker={currentLocker} 
        handleSelectItem={setCurrentItem}
      />
    )
  }

  /**
   * Checks the variable state to confirm if the Locker Item View should 
   * be shown, and if so returns it.
   */
  const renderLockerItemView = () => {
    if (currentItem === null || currentLocker === null || completionRecord === null || inventoryData === null) {
      console.log(currentItem);
      console.log(currentLocker);
      console.log(completionRecord);
      console.log(inventoryData);
  
      console.debug("preq is null!");
      return null;
    }

    return (
      <LockerItemView 
        workflowViews={Views} 
        setCurrentView={setCurrentView} 
        setInventoryTranscriptUpdated={setInventoryTranscriptUpdated}
        currentItem={currentItem} 
        completionRecord={completionRecord} 
        setCompletionRecord={setCompletionRecord}
      />
    );
  }

  /**
   * 
   */
  return render();
};

export default CompleteInventoryWorkflow;