import Constants from "./Constants";

const STORAGE_KEY = 'erma-auth';


/**
 * Store the Auth object to local storage
 * @param {string} jwt 
 */
export const storeAuth = (jwt) => {
  let auth = {
    jwt: jwt
  }
  localStorage.setItem(STORAGE_KEY, JSON.stringify(auth));
}

/**
 * Removes the Auth object from the local storage
 */
export const clearAuth = () => {
  localStorage.removeItem(STORAGE_KEY);
}

/**
 * Get the Auth object from the local storage
 */
export const getAuth = () => {
  var auth = null;
  var authString = localStorage.getItem(STORAGE_KEY);

  if (authString !== null) {
    auth = JSON.parse( authString );
  }

  return auth;
}

/**
 * Checks to see if the user is authenticated, if they are returns TRUE, otherwise FALSE
 */
export const isUserAuthenticated = () => {
  var auth = getAuth();
  
  //TODO: Probally needs some more strength around it, but the calls to APIs will fail if token is invalid!
  let isAuthorised = auth !== undefined && auth !== null && auth?.jwt !== undefined && auth?.jwt !== null;
  
  // If the user is not authorised we need to bug out ealry, cause the getInventoryRole
  // call below will fail cause a unauthorised user has a null jwt.
  if (!isAuthorised) {
    return false;
  }

  let isTokenExpired = isJwtExpired(auth.jwt);
  let role = getInventoryRole(auth.jwt);
  
  if (isTokenExpired || (role !== Constants.UserRole.Manager && role !== Constants.UserRole.User) ) {
    isAuthorised = false;
  }

  return isAuthorised;
}

/**
 * Returns the user ERMA Inventory role from the JWT
 */
export const getInventoryRole = (jwt) => {
  return getJwtBodyField(jwt, "eir");
}

/**
 * Checks if the user holds the Manager Role, if they do returns true.
 * @returns 
 */
export const isManager = () => {
  let role = getInventoryRole( getAuth().jwt );

  return role === Constants.UserRole.Manager;
}

/**
 * Returns true if the expire time in the JWT has past
 */
const isJwtExpired = (jwt) => {
  let expiresAt = getJwtBodyField(jwt, "exp");
  let timeNow = Math.floor(Date. now() / 1000);

  return expiresAt < timeNow;
}

/**
 * Extracts fields from the JWT
 */
const getJwtBodyField = (jwt, fieldName) => {
  let jwtBody = jwt.split(".")[1];
  jwtBody = JSON.parse( atob(jwtBody) );
  return jwtBody[fieldName];
}