
import React from 'react';
import logo from '../../assets/logo.png';
import Button from '@material-ui/core/Button';
import Constants from '../../utils/Constants';

const styles = {
  content: {
    backgroundColor: "rgb(233, 234, 237)",
    height: "100%"
  },
  logo: {
    width: "95%",
    maxWidth: "700px",
    marginTop: "2em",
    marginBottom: "2em"
  }
}


const WelcomePage = function() {

  return (
    <div className="view" style={styles.content}>
      <img src={ logo } style={styles.logo} alt="ERMA - Emergenecy Response Member Availability" />
      <div>
        <Button variant="primary" href={Constants.RoutePath.SignUp}>Not a member? Sign Up!</Button>
      </div>
    </div>
  );
  
}

export default WelcomePage;