import React, { useEffect, useState } from 'react';
import SortableTree, { getNodeAtPath } from '@nosferatu500/react-sortable-tree';
import { changeNodeAtPath, addNodeUnderParent, walk, removeNode} from '@nosferatu500/react-sortable-tree';
import SplitPane from 'react-split-pane';
import '@nosferatu500/react-sortable-tree/style.css';

import Constants from '../../../utils/Constants'
import ServiceAPI from '../../../service/ServiceAPI';
import ContainerInputFields from './ContainerInputFields';
import ContainerModel from '../../../model/ContainerModel';
import ItemInputFields from './ItemInputFields';
import ItemModel from '../../../model/ItemModel';
import InventoryInputFields from './InventoryInputFields';
import InventoryModel from '../../../model/InventoryModel';
import { useParams } from 'react-router-dom';


const InventoryEdit = function(props) {
  const params = useParams();

  const [inventoryId, setInventoryId] = useState(null);
  const [inventoryData, setInventoryData] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItemChanged, setSelectedItemChanged] = useState(false);
  const [itemName, setItemName] = useState(null);
  const [itemDescription, setItemDescription] = useState(null);
  const [itemQuantity, setItemQuantity] = useState(null);
  const [itemSealed, setItemSealed] = useState(0);
  const [itemVersion, setItemVersion] = useState("0.0.0");


  const loadInventory = function() {
    if (inventoryData !== null) {
      return;
    }

    ServiceAPI.Inventory().read(params.inventoryId).then(
      (data) => {
        var treeData = [convertInventoryToTreeModel(data)];
        setInventoryData(treeData);
        setInventoryId(params.inventoryId);
      }
    )
  }
  


  const convertInventoryToTreeModel = function(inventory) {
    var fnConvertNodes = function(node) {
      node.title = node.name;
      node.children = [];

      if (node.items && node.items.length > 0) {
        for (var index = 0; index < node.items.length; index++) {
          node.children.push(fnConvertNodes(node.items[index]));
        }
      }

      if (node.containers && node.containers.length > 0) {
        for (var index = 0; index < node.containers.length; index++) {
          node.children.push(fnConvertNodes(node.containers[index]));
        }

      }

      node.children.sort((a,b) => {
        return a.displayOrder > b.displayOrder ? 1 : -1;
      });

      return node;
    }

    return fnConvertNodes(inventory);
  };

  /**
   * 
   * @param {*} rowInfo 
   */
  const onClick_ViewTreeNodeDetails = function(rowInfo) {
    setSelectedItem(rowInfo);
    
    let isItem = rowInfo.node.type === Constants.InventoryType.Item;
    let isContainer = rowInfo.node.type === Constants.InventoryType.Container;
    let isInventory = rowInfo.node.type === Constants.InventoryType.Inventory;

    setItemName(rowInfo.node.name);
    setItemDescription(rowInfo.node.description == null ? "" : rowInfo.node.description);

    if (isInventory) {
      setItemVersion(rowInfo.node.version);
    }

    if (isItem) {
      setItemQuantity(rowInfo.node.quantity);
    }

    if (isContainer) {
      setItemSealed(rowInfo.node.sealed);
    }
  }

  /**
   * 
   * @param {*} moveInfo 
   */
  const updateDisplayOrder = function(moveInfo) {

    let fnPersitIndex = (treeNode) => {
      let node = treeNode.node;
      node.displayOrder = treeNode.treeIndex;  
      
      switch (node.type) {

        case Constants.InventoryType.Container:
          ServiceAPI.Container().updateContainer(node).then( 
            (json) => { console.log("Update Complete:", json)} 
          );
          break;

        case Constants.InventoryType.Item:
          let itemModel = new ItemModel(node);

          ServiceAPI.Item().updateItem(itemModel).then( 
            (json) => { console.log("Update Complete:", json)} 
          );
          break;
      }
    };

    let fnCheckForMove = () => {
      var nextParentPath = [...moveInfo.nextPath]
       
      // pop the last ite, casue that is the index of the node being moved. We want the path to its parent
      nextParentPath.pop();

      // Pull the parent base on the path array so that we can get the items id
      var nextParentId = getNodeAtPath({ 
        treeData: inventoryData,
        path: nextParentPath,
        getNodeKey: ({ treeIndex }) => treeIndex
      }).node.id;

      // Check if its parent has changed
      if (moveInfo.node.containerId !== nextParentId) {

        // Different update methods based on the type of item being moved.
        if (moveInfo.node.type === Constants.InventoryType.Item) {
          // Update the item in the treedata, as the UPDATE will save the container Id as well
          changeNodeAtPath({
            treeData: inventoryData,
            path: moveInfo.path,
            newNode: (treeNode) => {
              treeNode.node.containerId = nextParentId;
              return treeNode;
            },
            getNodeKey: ({ treeIndex }) => treeIndex
          });
        } else if (moveInfo.node.type === Constants.InventoryType.Container) {
          // Call a move function for containers as there is additional logic for containers. Eg, Locker container cant be moved to a new parent.
          ServiceAPI.Container().moveContainer(moveInfo.node.id, nextParentId).then( (json) => { console.log("MoveCOntainer", json)});
        }
      }
    }

    fnCheckForMove();

    walk({
      treeData: inventoryData,
      getNodeKey: ({ treeIndex }) => treeIndex,
      callback: fnPersitIndex,
      ignoreCollapsed: false
    });
 
  }
  
  /**
   * 
   * @param {*} event 
   */
  const addNewItemToContainer = (event) => {
    let newItem = new ItemModel();

    newItem.setName("New Item");
    newItem.setDescription("");
    newItem.setQuantity(1);
    newItem.setContainerId(selectedItem.node.id);
    
    let treeNode = newItem.data;
    treeNode.title = newItem.getName();

    let newTreeModel = addNodeUnderParent({
      treeData: inventoryData,
      newNode: treeNode,
      parentKey: selectedItem.treeIndex,
      getNodeKey: ({ treeIndex }) => treeIndex,
      ignoreCollapsed: true,
      expandParent: true,
      addAsFirstChild: false
    });

    setInventoryData(newTreeModel.treeData);
    
    let newPath = selectedItem.path.slice();
    newPath.push(newTreeModel.treeIndex);

    // Set the display order to the items tree index
    newItem.setDisplayOrder( newTreeModel.treeIndex );

    ServiceAPI.Item().createItem(newItem).then( 
      (json) => { 
        changeNodeAtPath({
          treeData: newTreeModel.treeData,
          path: newPath,
          newNode: (treeNode) => treeNode.node.id = json.id,
          getNodeKey: ({ treeIndex }) => treeIndex
        });
      } 
    );
  }

  /**
   * Creates a new container based on the supplied container item. Shoudld only be 
   * called from addNewHeadingContainerToContainer and addNewContainerToContainer
   * 
   * @param {*} container 
   */
  const addNewContainer = (newContainer) => {
    newContainer.setInventoryId(inventoryId);
    newContainer.setParentContainerId(selectedItem.node.type !== Constants.InventoryType.Inventory ? selectedItem.node.id : null)
    newContainer.setDescription("");
    newContainer.setSealed(0);

    let treeNode = newContainer.data;
    treeNode.title = newContainer.getName();

    let newTreeModel = addNodeUnderParent({
      treeData: inventoryData,
      newNode: treeNode,
      parentKey: selectedItem.treeIndex,
      getNodeKey: ({ treeIndex }) => treeIndex,
      ignoreCollapsed: true,
      expandParent: true,
      addAsFirstChild: false
    });

    setInventoryData(newTreeModel.treeData)

    let newPath = selectedItem.path.slice();
    newPath.push(newTreeModel.treeIndex);

    // Set the display order to the items tree index
    newContainer.setDisplayOrder( newTreeModel.treeIndex );

    ServiceAPI.Container().createContainer(newContainer).then( 
      (json) => { 
        changeNodeAtPath({
          treeData: newTreeModel.treeData,
          path: newPath,
          newNode: (treeNode) =>  treeNode.node.id = json.id ,
          getNodeKey: ({ treeIndex }) => treeIndex
        });
      } 
    );
  }

  /**
   * Creates a new Heading Container item
   * @param {*} event 
   */
  const addNewHeadingContainerToContainer = (event) => {
    let newContainer = new ContainerModel();

    newContainer.setName("New Heading");
    newContainer.setHeading(1);

    addNewContainer(newContainer);
  }

  /**
   * Creates a new Container item
   * @param {*} event 
   */
  const addNewContainerToContainer = (event) => {
    let newContainer = new ContainerModel();

    newContainer.setName("New Container");
    newContainer.setHeading(0);

    addNewContainer(newContainer);
  }

  /**
   * 
   * @param {*} event 
   */
  const updateFieldValue = (event) => {
    var field = event.target.getAttribute('data-field');
    var updateFn = null;

    switch (field) {
      
      case "name":
        setItemName(event.target.value);
        updateFn = (treeNode) => {
          treeNode.node.name = event.target.value;
          treeNode.node.title = event.target.value;
          return treeNode;
        }
        break;
      
      case "description":
        setItemDescription(event.target.value);
        updateFn = (treeNode) => {
          treeNode.node.description = event.target.value;
          return treeNode;
        }
        break;

      case "quantity":
        setItemQuantity(event.target.value);
        updateFn = (treeNode) => {
          treeNode.node.quantity = event.target.value;
          return treeNode;
        }
        break;

      case "sealed":
        let isSealed = event.target.checked === true ? 1 : 0;
        setItemSealed(isSealed);
        updateFn = (treeNode) => {
          treeNode.node.sealed = isSealed
          return treeNode;
        }
        break;

      case "version":
        setItemVersion(event.target.value);
        updateFn = (treeNode) => {
          treeNode.node.version = event.target.value
          return treeNode;
        }
        break;
    }
    
    changeNodeAtPath({
      treeData: inventoryData,
      path: selectedItem.path,
      newNode: updateFn,
      getNodeKey: ({ treeIndex }) => treeIndex
    });

    setSelectedItemChanged(true);
  }

  /**
   * 
   */
  const saveUpdatedItem = (event) => {
    if (selectedItemChanged === false) {
      return;
    }
    
    setSelectedItemChanged(false);
    
    var selected = selectedItem.node;      

    switch (selected.type) {

      case Constants.InventoryType.Inventory:
        let inventoryModel = new InventoryModel(selected);

        ServiceAPI.Inventory().updateInventory(inventoryModel).then(
          (json) => { console.log("Update Complete:", json); }
        );
        break;

      case Constants.InventoryType.Container:
        ServiceAPI.Container().updateContainer(selected).then( 
          (json) => { console.log("Update Complete:", json)} 
        );
        break;

      case Constants.InventoryType.Item:
        let itemModel = new ItemModel(selected);

        ServiceAPI.Item().updateItem(itemModel).then( 
          (json) => { console.log("Update Complete:", json)} 
        );
        break;
    }
    
  }

  /**
   * 
   */
  const deleteItem = (e) => {
    var selected = selectedItem.node;

    let outcome = removeNode({
      treeData: inventoryData,
      path: selectedItem.path,
      getNodeKey: ({ treeIndex }) => treeIndex
    });

    setInventoryData(outcome.treeData);
    setSelectedItem(null);

    ServiceAPI.Item().deleteItem(selected.id).then( 
      (json) => { console.log("Delete Item Complete:", json)} 
    );
  }

  /**
   * 
   */
  const deleteContainer = (e) => {
    var selected = selectedItem.node;
    console.log(selectedItem);
    let outcome = removeNode({
      treeData: inventoryData,
      path: selectedItem.path,
      getNodeKey: ({ treeIndex }) => treeIndex
    });
    
    setInventoryData(outcome.treeData);
    setSelectedItem(null);

    ServiceAPI.Container().deleteContainer(selected.id).then( 
      (json) => { console.log("Delete Container Complete:", json)} 
    );
  }

  /**
   * 
   */
  const render = function() {
    return (
      <div className="main">
        { renderLoading() }

        <SplitPane split="vertical" minSize="60%" style={{position: "static"}} >
          <div style={{ height: '99%' }}>
            { renderInventoryTree() }
          </div>
          <div>
            { renderSelectedItemDetails() }
          </div>
        </SplitPane>
      </div>
    )
  };

  const renderLoading = function() {
    return inventoryData === null ? <h3>Loading Inventory</h3> : null;
  }

  const renderInventoryTree = function() {
    if ( inventoryData === null ) {
      return null;
    }

    return (
      <SortableTree 
        treeData={inventoryData} 
        onChange={treeData => setInventoryData( treeData )}
        onMoveNode={ updateDisplayOrder }
        generateNodeProps={rowInfo => ({
          buttons: [
            <button
              className="btn btn-outline-success"
              style={{
                verticalAlign: 'middle',
              }}
              onClick={() => onClick_ViewTreeNodeDetails(rowInfo)}
            >
              ℹ
            </button>
          ],
        })}
      />
    );
  }

  const renderSelectedItemDetails = function() {
    if (selectedItem === null) {
      return <div/>;
    }

    let isInventory = selectedItem.node.type === Constants.InventoryType.Inventory;
    let isItem = selectedItem.node.type === Constants.InventoryType.Item;
    let isContainer = selectedItem.node.type === Constants.InventoryType.Container;
    let isParent = selectedItem.node.children !== undefined && selectedItem.node.children.length > 0;
    let isHeadingContainer = isContainer && selectedItem.node.heading === 1 ? 1 : 0;

    let inventoryFields = isInventory ? (
      <InventoryInputFields 
        itemName={ itemName }
        itemVersion={ itemVersion }
        handleOnNewContainer={ addNewHeadingContainerToContainer }
        handleOnChange={ updateFieldValue }
        handleOnBlur={ saveUpdatedItem }
      />
    ) : null;

    let itemFields = isItem ? (
      <ItemInputFields
        itemName={ itemName } 
        itemDescription={ itemDescription }
        itemQuantity={ itemQuantity}
        handleOnChange={ updateFieldValue } 
        handleOnBlur={ saveUpdatedItem } 
        handleOnDelete={ deleteItem }
      />
    ) : null;

    let continerInput = isContainer ? (
      <ContainerInputFields 
        itemName={ itemName } 
        itemDescription={ itemDescription }
        itemSealed= { itemSealed }
        itemIsHeadingContainer={ isHeadingContainer }
        handleOnChange={ updateFieldValue } 
        handleOnBlur={ saveUpdatedItem } 
        handleOnNewHeading={ addNewHeadingContainerToContainer }
        handleOnNewItem={ addNewItemToContainer }
        handleOnNewContainer={ addNewContainerToContainer }
        handleOnDelete={ isParent ? null : deleteContainer }
      />
    ) : null;
    

    return (
      <div style={{margin: '.5em'}}>
        { inventoryFields }
        { continerInput }
        { itemFields }
      </div>
    );
  }

  //const render

  useEffect(() => {
    loadInventory();
  }, []);
  

  return render();
};

export default InventoryEdit;