import React from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';
import Constants from '../../utils/Constants';
import useAuthentication from '../../hooks/useAuthentication';

import Menu from './Menu';

import WelcomePage from '../view/welcome';
import Login from '../view/login';
import Logout from '../view/logout';

import InventoryList from '../view/inventory/InventoryList';
import InventoryCreate from '../view/inventory/InventoryCreate';
import InventoryEdit from '../view/inventory/InventoryEdit';

import InventoryPdf from '../view/pdfGenerate/InventoryPdf'; 
import LockerContentView from '../view/complete/LockerContentView';
import CompleteInventoryWorkflow from '../view/complete/CompleteInventoryWorkflow';

const Layout = function(props) {
  const [isAuthenticated] = useAuthentication();

  const RequireAuth = function({ children }) {
    return isAuthenticated ? children : <Navigate to={Constants.RoutePath.Login} />;
  }

  return (
    <div id="layout">
      <Menu />        
      
      <Routes>
        <Route path={Constants.RoutePath.Home} exact element={
          <WelcomePage />
        }/>

        <Route path={Constants.RoutePath.Login} exact element={
          <Login /> 
        }/>

        <Route path={Constants.RoutePath.Logout} exact element={
          <Logout /> 
        }/>

        /* Secure Routes Below */ 

        <Route path={Constants.RoutePath.Inventory.List} exact element={
          <RequireAuth>
            <InventoryList />
          </RequireAuth>
        }/>
        <Route path={Constants.RoutePath.Inventory.Create} exact element={
          <RequireAuth>
            <InventoryCreate />
          </RequireAuth>
        }/>
        <Route path={Constants.RoutePath.Inventory.Edit(':inventoryId')} exact element={
          <RequireAuth>
            <InventoryEdit />
          </RequireAuth>
        }/>
        <Route path={Constants.RoutePath.Inventory.Pdf(':inventoryId')} exact element={
          <RequireAuth>
            <InventoryPdf />
          </RequireAuth>
        }/>
        <Route path={Constants.RoutePath.Inventory.Complete(':inventoryId')} exact element={
          <RequireAuth>
            <CompleteInventoryWorkflow />
          </RequireAuth>
        }/>
        <Route path={Constants.RoutePath.Inventory.CompleteLocker(':lockerId')} exact element={
          <RequireAuth>
            <LockerContentView />
          </RequireAuth>
        }/>
      </Routes>
    </div>
  );
};

export default Layout;

