import React from 'react';
import { useState, useEffect } from 'react';
import ServiceAPI from '../../../service/ServiceAPI';
import Constants from '../../../utils/Constants';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { isManager } from '../../../utils/AuthUtils';
import { useNavigate } from 'react-router-dom';

const CardClasses = makeStyles({
  card: {
    maxWidth: 150
    
  },
  media: {
    height: 140,
  },
});


const InventroyList = function(props) {

  const navigate = useNavigate();

  let [inventories, setInventories] = useState(null);

  useEffect(() => {
    ServiceAPI.Inventory().getListing().then((data) => {
      setInventories(data);
    });
  }, []);

  const onClick_CompleteInventory = (id) => {
    if (!Constants.FeatureToggle.CompleteInventoryEnabled) {
      return; // Do nothing if the feature toggle is off.
    }
    
    navigate(Constants.RoutePath.Inventory.Complete(id));
  }

  const onClick_EditInventory = (id) => {
    navigate(Constants.RoutePath.Inventory.Edit(id));
  }

  const onClick_PdfInventory = (id) => {
    navigate(Constants.RoutePath.Inventory.Pdf(id));
  }

  const onClick_CreateNewInventory = () => {
    navigate(Constants.RoutePath.Inventory.Create);
  }

  const render = function() {
    if (inventories === null) {
      return <h1>Loading Inventory List</h1>
    }

    return (
      <div style={{padding: '2em', margin: 'auto'}}>
        { inventories.map((item) => { return InventoryCard(item); }) }
        { NewInventoryCard() }
      </div>
    );
  }

  const InventoryCard = function(inventory) {

    let editButton = 
      <Button size="small" color="primary" onClick={() => { onClick_EditInventory(inventory.id) }}>
        Edit
      </Button>
    ;
  
    return <Card key={inventory.id} style={{maxWidth: 150, display: 'inline-block', margin: '1em'}}>
      <CardActionArea onClick={(e) => { onClick_CompleteInventory(inventory.id) } }>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {inventory.name} 
          </Typography>
          <Typography gutterBottom variant="h5" component="h2">
            v{inventory.version}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        
        <Button size="small" color="primary" onClick={() => { onClick_PdfInventory(inventory.id) }}>
          Print
        </Button>
        { isManager() ? editButton : null }
      </CardActions>
    </Card>
  }

  const NewInventoryCard = function() {
    if ( isManager() !== true ) {
      return null;
    }
  
    return <Card style={{maxWidth: 150, display: 'inline-block', margin: '1em'}}>
      <CardActionArea>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            New Inventory
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="primary" onClick={onClick_CreateNewInventory}>
          Create
        </Button>
      </CardActions>
    </Card>
  }

  return render()
}

export default InventroyList;
