import { useState, useEffect } from "react";
import ServiceAPI from "../../../service/ServiceAPI";

import { Button, Grid, Box } from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress'; 
import Constants from '../../../utils/Constants';

const LockerSelectionView = (props) => {
  console.assert(props.workflowViews);
  console.assert(props.setCurrentView);
  console.assert(props.inventoryData);
  console.assert(props.handleSelectedLocker);
  console.assert(props.completionRecord);

  console.log("LockerSelectionView->InventoryData", props.inventoryData);
  
  /**
   * 
   * @param {*} container 
   */
  const handleClick_selectLocker = (container) => {
    props.handleSelectedLocker(container);
    props.setCurrentView(props.workflowViews.LockerContentView);
  }

  /**
   * 
   */
  const handleClick_inventoryComplete = (completionRecord) => {
    if (completionRecord.getCompleted() === null) {
      completionRecord.setCompleted(new Date().toISOString());      
      ServiceAPI.CompletionRecord().updateExistingCompletionRecord(completionRecord);
    }

    props.setCurrentView(props.workflowViews.CompletionRecordSelectionView);
  }

  /**
   * 
   * @param {*} container 
   * @param {*} completionRecord 
   * @returns 
   */
  const calculateCompletedPercentage = (container, completionRecord) => {
    let containerContents = container.flattenContainerContents();
    let totalComplete = 0;
    let totalHeadingContainers = 0;

    // For future me, this method of calculating the percentage is very ineffecinet and
    // there will performance issues with loading large inventories with many lockers/items.
    // This will need to be improved in the future. Basically, for every item in the 
    // flattened locker see if a Completion Record Transcript record exists with a matching ID.
    containerContents.forEach(element => {
      if (element.getHeading && element.getHeading() === 1) {
        totalHeadingContainers++;
        return;
      }

      if (completionRecord.getTranscripts().filter((crt) => {
        let isContainer = element.getType() === Constants.InventoryType.Container;
        return crt.getItemId() == element.getId() && crt.isContainer() == isContainer;
      }).length > 0) {
        totalComplete++;
      }
    });

    return Math.round((totalComplete / (containerContents.length - totalHeadingContainers)) * 100);
  }

  /**
   * 
   */
  const render = function() {
    if (props.inventoryData === null) {
      return <h1>Loading Inventory...</h1>
    }
    
    let percentages = [];

    props.inventoryData.getContainers().map( (container) => {
      let containerId = container.getId();
      let completionPercentage = calculateCompletedPercentage(container, props.completionRecord);

      percentages.push({id: containerId, percentage: completionPercentage});
    });



    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="left"
        spacing={4}
        m={2}
      >
        { renderLockers(percentages) }
        { renderCompleteInventoryButton(percentages) }
      </Grid>
    );
  }

  /**
   * 
   */
  const renderProgressBar = (percent) => {

    return (
      <Box display="flex" alignItems="center">
        <Box width="100%" mr={1}>
          <LinearProgress variant="determinate" value={percent} />
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">{`${Math.round(percent)}%`}</Typography>
        </Box>
      </Box>
    );
  }
  /**
   * 
   */
  const renderLockers = (percentages) => {
    return props.inventoryData.getContainers().map( (container) => {
      return (
        <Card style={{maxWidth: '80%', margin: '2em', backgroundColor: 'whitesmoke'}}>
          <CardActionArea>
            <CardContent onClick={() => handleClick_selectLocker(container) }>
              <Typography gutterBottom variant="h5" component="h2">
                { container.getName() }
              </Typography>
              { renderProgressBar( percentages.filter((o) => o.id == container.getId())[0].percentage ) }
            </CardContent>
          </CardActionArea>
        </Card>
      );
    });
  }

  const renderCompleteInventoryButton = (percentages) => {
    let allComplete = true;

    percentages.forEach((o) => {
      if (allComplete === false) {
        return;
      }

      allComplete = (o.percentage === 100);
    });


    return (
      <Button 
        variant="contained" 
        color="primary" 
        size="large" 
        style={{margin: '1em'}} 
        disabled={ !allComplete } 
        onClick={() => { handleClick_inventoryComplete(props.completionRecord) }}
      >
        Inventory Complete
      </Button>
    );
  }

  return render();
}

export default LockerSelectionView;