import { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";

import { FormControlLabel, Button, RadioGroup, Radio, Grid, Box } from "@material-ui/core";

import Constants from "../../../utils/Constants";
import LoadingIndicator from "../../utils/LoadingIndicator";
import ServiceAPI from "../../../service/ServiceAPI";
import CompletionTranscriptRecord from "../../../model/CompletionTranscriptRecord";
import ContainerModel from "../../../model/ContainerModel";
import ItemModel from "../../../model/ItemModel";
import { findItemCompletionTranscriptRecord, updateItemsCompletionTranscriptRecord } from "./CompleteInventoryUtils";

const Styles = {
  inventoryItemRow: {
    borderTop: "1px solid lightgrey"
  },
  headingContainer: {
    fontWeight: 'bold',
    marginTop: '1em',
    borderTop: "0px solid grey"
  }
};

const LockerContentView = (props) => {
  console.assert(props.workflowViews);
  console.assert(props.setCurrentView);
  console.assert(props.setInventoryTranscriptUpdated);
  console.assert(props.selectedLocker);
  console.assert(props.completionRecord);
  console.assert(props.setCompletionRecord);
  console.assert(props.handleSelectItem);

  const [ flattendedContent, setFlattenedContent ] = useState(null);

  useEffect(() => {
    setFlattenedContent( props.selectedLocker.flattenContainerContents() );
  }, []);

  /**
   * 
   * @param {*} e 
   * @param {*} isPresnet 
   */
  const handleClick_itemPresent = (item, isContainer, isPresent) => {
    let ctr = new CompletionTranscriptRecord();
    ctr.setCompletionRecordId(props.completionRecord.getId());
    ctr.setItemId(item.getId());
    ctr.setIsContainer(isContainer);
    ctr.setPresent(isPresent);

    // Need to find the original marked item transcript, if it exists, to get the notes field. 
    // Other wise the notes field data will be wiped with the old data/no data.
    let updatedCompletionRecord = props.completionRecord;
    let markedItemTranscript = findItemCompletionTranscriptRecord(updatedCompletionRecord, item);
    
    ctr.setNotes(markedItemTranscript?.getNotes()); 

    // TODO: This function has a delay when the user interacts due to the forcing a
    //       refresh of the whole component, rather then just the radio button 
    //       componet. This is fine for now, but will need to adjust at some point
    //       to rerender only the radio buttons for the item updated.
    
    // Send the request to the API to update/create the transcript record
    ServiceAPI.CompletionRecord().createInventoryCompletionTranscriptRecord(ctr);

    // Now we have updated the remote DB, we can update the local copy of the 
    // transcript record to refelct the change. We are maintaining the local 
    // copy rather than getting it from the servers, because we dont wanna hit 
    // the DB for the full inventory listing every single time a item is marked in. 
    updatedCompletionRecord = updateItemsCompletionTranscriptRecord(updatedCompletionRecord, markedItemTranscript, ctr);

    props.setInventoryTranscriptUpdated(new Date().getTime()); // This causes the LockerContentView componet to render so that the radio buttons update (See TODO)
    props.setCompletionRecord(updatedCompletionRecord);
  }

  /**
   * 
   */
  const handleClick_itemSelected = (item) => {
    props.setCurrentView(props.workflowViews.LockerItemView);
    props.handleSelectItem(item);
  }

  /**
   * Returns the user back to the locker selection when the click on the 
   * Locker Complete button at the end of the page.
   */
  const handleClick_LockerComplete = () => {
    props.setCurrentView(props.workflowViews.LockerSelectionView);
  }

  /**
   * 
   * @returns 
   */
  const render = () => {
    if (flattendedContent === null) {
      return <LoadingIndicator />
    }

    return (
      <Box marginLeft={1} marginRight={1} display={'flex'} flexDirection={'column'} height={'90%'}>
        <Box>
          <h1>{ props.selectedLocker.getName() }</h1>
          
          <Grid container row="true">
            <Grid item xs>
              &nbsp;
            </Grid>
          
            <Grid item xs={5}>
              Present
            </Grid>
          
          </Grid>
        </Box>

        <Box overflow={'auto'} flex={1} height={'100%'}>
        {
          flattendedContent.map( (item, index) => {
            if (index === 0) {
              // Dont render the first item in the list, it is the locker itself.
              return;
            }
            return renderItem(item);
          })
        }
        </Box>

        <Box paddingTop={'1em'}>
          <Button variant="contained" color="primary" size="large" onClick={handleClick_LockerComplete}>
            Done
          </Button>
        </Box>
      </Box>
    );
  }

  /**
     * 
     * @param {*} item 
     * @returns 
     */
  const renderItem = (item) => {
    let itemTranscript = props.completionRecord.getTranscripts().filter( 
      (t) => {
        let isModelTypeContainer = item.getType() === Constants.InventoryType.Container ? 1 : 0;
        return t.getItemId() === item.getId() && t.isContainer() === isModelTypeContainer;
      }
    );
    let itemPresences = itemTranscript.length > 0 ? itemTranscript[0].getPresent() : null;
    let isContainer = item.getType() === Constants.InventoryType.Container ? 1 : 0;
    let isHeading = isContainer && item.getHeading() === 1;

    let jsx = (
      <Box sx={ isHeading ? Styles.headingContainer : Styles.inventoryItemRow } margin={1}>
        <Grid container row="true" alignItems="center" key={(item.getType() + item.getId())} > 
          { isHeading ? renderHeadingContainer(item) : renderRecordableItem(item, itemPresences, isContainer) }
        </Grid>
      </Box>
    );

    return jsx;
  }

  /**
   * Renders the control content for a item that can be marked as present or not.
   * @param {*} item 
   * @returns 
   */
  const renderRecordableItem = (item, itemPresences, isContainer) => {
    let itemPresencesIsIssue = itemPresences === "ISSUE";
    itemPresences = (itemPresencesIsIssue ? "YES" : itemPresences);

    let inventoryDepthMargin = (6 * item?.getInventoryDepth());
    return (
      <>
        <Grid justifyContent="flex-start" item xs >
          <Box display="flex" justifyContent="flex-start" textAlign="left" style={{marginLeft: `${inventoryDepthMargin}px`}}> 
            <Link onClick={ () => handleClick_itemSelected(item) }>
              { item.getName() } { !isContainer && item.getQuantity() > 1 ? ' x' + item.getQuantity() : ''}
            </Link>
          </Box>
        </Grid>
        
        <Grid alignItems="center" item xs={5}>
          <Box display="flex" justifyContent="center">  
            <RadioGroup row={true} value={itemPresences}>
              <FormControlLabel
                control={<Radio value="YES" onChange={ () => handleClick_itemPresent(item, isContainer, "YES") } />}
                label={itemPresencesIsIssue ? "Issue" : "Yes"}
                labelPlacement="start"
              />
              
              <FormControlLabel
                control={<Radio value="NO" onChange={ () => handleClick_itemPresent(item, isContainer, "NO") }/>}
                label="No"
                labelPlacement="start"
              />
            </RadioGroup>
          </Box>
        </Grid>
      </>
    );
  }

  /**
   * Renders the Heading Container which cant be marked as present or not as i is just a heading.
   * @param {*} item 
   */
  const renderHeadingContainer = (item) => {
    return (
      <Grid justifyContent="flex-start" item xs >
        <Box display="flex" justifyContent="flex-start" textAlign="left"> 
          { item.getName() }
        </Box>
      </Grid>
    );
  }
  
  return render();
}

export default LockerContentView;