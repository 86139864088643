import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Constants from '../../utils/Constants';
import { useNavigate } from 'react-router-dom';
import useAuthentication from '../../hooks/useAuthentication';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));


const Menu = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [ isAuthenticated, login, logout ] = useAuthentication();

  let handleLogout = () => {
    logout();
    navigate(Constants.RoutePath.Logout);
  }

  let handleHamburger = () => {
    let route = isAuthenticated
      ? Constants.RoutePath.Inventory.List
      : Constants.RoutePath.Home
    ;

    navigate(route);
  }
  
  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={ handleHamburger }>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            ERMA Inventory
          </Typography>
          { isAuthenticated
            ? <Button color="inherit" onClick={handleLogout}>Logout</Button> 
            : <Button color="inherit" href={Constants.RoutePath.Login}>Login</Button> }
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default Menu;
