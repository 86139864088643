
const API_BASE_URL = 'https://api.erma-app.com';

module.exports = Object.freeze(
  {
    FeatureToggle: {
      CompleteInventoryEnabled: true
    },

    ServiceAPI: {
      baseUrl: `${API_BASE_URL}/api/inventory/`,
      authenticateUrl: `${API_BASE_URL}/api/authenticate/`
    },

    Http: {
      Method: {
        GET: 'GET',
        POST: 'POST',
        PUT: 'PUT',
        DELETE: 'DELETE'
      },
      
      Status: {
        OK: 200,
        INTERNAL_ERROR: 500
      },

      Param: {
        Id: "id"
      }
    },
    
    InventoryType: {
      Item: 'item',
      Container: 'container',
      Inventory: 'inventory'
    },

    UserRole: {
      Manager: "MANAGER",
      User: "USER"
    },
    
    RoutePath: {
      Home: "/",
      SignUp: "/signup",
      Login: "/login",
      Logout: "/logout",

      Inventory: {
        List: '/inventory/listing',
        Create: '/inventory/create',
        Edit: (id) => { return `/inventory/edit/${id}`; },
        Pdf: (id) => { return `/inventory/print/${id}`; },
        Complete: (id) => { return `/inventory/complete/${id}`; },
        CompleteLocker: (id) => { return `/inventory/complete/locker/${id}` }
      }
    }

  }
);
