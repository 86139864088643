import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';

const ContainerInputFields = function(props) {
  const isHeading = props.itemIsHeadingContainer && props.itemIsHeadingContainer === 1;

  const renderNonHeadingContainerInputFields = () => {
    if (isHeading) {
      return;
    }

    return (
      <>
        <TextField 
          label="Description" 
          variant="outlined" 
          value={ props.itemDescription } 
          onChange={ props.handleOnChange } 
          onBlur={ props.handleOnBlur } 
          inputProps={ {'data-field': "description" }} 
          margin="normal"
          fullWidth
        />

        <Switch 
          checked={props.itemSealed === 1 ? true : false} 
          onChange={ props.handleOnChange } 
          onBlur={ props.handleOnBlur }  
          inputProps={ {'data-field': "sealed" }} 
        />
        Sealed
      </>
    );
  }

  const render = () => {
    return (
      <div>
        <div>
          <Button variant="contained" style={{margin: ".5em"}} onClick={ props.handleOnNewItem }>New Item</Button>
          <Button variant="contained" style={{margin: ".5em"}} onClick={ props.handleOnNewContainer }>New Container</Button>
          <Button variant="contained" style={{margin: ".5em"}} onClick={ props.handleOnNewHeading }>New Heading</Button>
          
          { props.handleOnDelete ? <Button variant="contained" style={{margin: ".5em"}} onClick={ props.handleOnDelete }>Delete</Button> : null }
        </div>

        <div>

          <TextField 
            label={ isHeading ? "Heading" : "Name" }
            variant="outlined" 
            value={ props.itemName } 
            onChange={ props.handleOnChange } 
            onBlur={ props.handleOnBlur } 
            inputProps={ {'data-field': "name" }} 
            margin="normal"
            fullWidth
          />

          { renderNonHeadingContainerInputFields() }
        </div>
      </div>
    );
  }

  return render();
}

export default ContainerInputFields;
