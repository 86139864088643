import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const ItemInputFields = function(props) {

  const render = () => {
    return (
      <div>
        <div>
          { props.handleOnDelete ? <Button variant="contained" onClick={ props.handleOnDelete }>Delete</Button> : null }
        </div>

        <div>

          <TextField 
            label="Name" 
            variant="outlined" 
            value={ props.itemName } 
            onChange={ props.handleOnChange } 
            onBlur={ props.handleOnBlur } 
            inputProps={ {'data-field': "name" }} 
            margin="normal"
            fullWidth
          />

          <TextField 
            label="Description" 
            variant="outlined" 
            value={ props.itemDescription } 
            onChange={ props.handleOnChange } 
            onBlur={ props.handleOnBlur } 
            inputProps={ {'data-field': "description" }} 
            margin="normal"
            fullWidth
          />

          <TextField 
            label="Quantity" 
            variant="outlined" 
            value={ props.itemQuantity } 
            onChange={ props.handleOnChange } 
            onBlur={ props.handleOnBlur } 
            inputProps={ {'data-field': "quantity" }} 
            margin="normal"
            fullWidth
          />
        </div>
      </div>
    );
  }

  return render();
}

export default ItemInputFields;
