
import Constants from '../utils/Constants';
import ContainerModel from './ContainerModel';

export default class InventoryModel {

    constructor(data) {

      // TODO: add validation for mandatory items
      let template = {
        id: 0,
        name: "",
        version: "",
        containers: [],
        type: Constants.InventoryType.Inventory
      };

      this.data = data ? data : template;
      
      if (this.data.containers && this.data.containers.length > 0) {
        let converetedContainers = [];
        this.data.containers.forEach((item) => {
            converetedContainers.push(new ContainerModel(item));
        });
        this.data.containers = converetedContainers;
      }

    }

    toJson = () => {
      return JSON.stringify(this.data);
    }

    getId = () => {
        return this.data.id;
    }

    setId = (value) => {
      this.data.id = value;
    }

    getName = () => {
        return this.data.name;
    }

    setName = (value) => {
      this.data.name = value;
    }

    getVersion = () => {
      return this.data.version;
    }

    setVersion = (value) => {
      this.data.version= value;
    }

    getContainers = () => {
      return this.data.containers;
    }

    getType = () => {
      return this.data.type;
    }  

    isContainer = () => {
      return false;
    }

}