
import Constants from '../utils/Constants'

export default class ItemModel {

  constructor(data) {

      const template = {
        id: 0,
        name: "",
        description: "",
        quantity: 0,
        image: null,
        displayOrder: 0,
        containerId: 0,
        type: Constants.InventoryType.Item,
        inventoryDepth: null
      };

      // TODO: add validation for mandatory items
      this.data = data ? data : template;
  }

  toJson = () => {
    return JSON.stringify(this.data);
  }

  getId = () => {
      return this.data.id;
  }

  setId = (value) => {
    this.data.id = value;
  }

  getImage = () => {
      return this.data.image ? this.data.image : null;
  }

  getDisplayOrder = () => {
    return this.data.displayOrder;
  }

  setDisplayOrder = (value) => {
    this.data.displayOrder = value;
  }
  
  getName = () => {
    return this.data.name;
  }

  setName = (value) => {
    this.data.name = value;
  }

  getDescription = () => {
    return this.data.description;
  }

  setDescription = (value) => {
    this.data.description = value;
  }

  getQuantity = () => {
    return this.data.quantity;
  }

  setQuantity = (value) => {
    this.data.quantity = value;
  }

  getContainerId = () => {
    return this.data.containerId;
  }

  setContainerId = (value) => {
    this.data.containerId = value;
  } 

  getType = () => {
    return this.data.type;
  }

  isContainer = () => {
    return false;
  }

  getInventoryDepth = () => {
    return this.data.inventoryDepth;
  }

  setInventoryDepth = (value) => {
    this.data.inventoryDepth = value;
  }
}