import { useState } from 'react';
import { Button, TextField, Box } from "@material-ui/core";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';

import { updateItemsCompletionTranscriptRecord, findItemCompletionTranscriptRecord } from './CompleteInventoryUtils';
import ServiceAPI from "../../../service/ServiceAPI";
import CompletionTranscriptRecord from "../../../model/CompletionTranscriptRecord";

const LockerItemView = (props) => {
  console.assert(props.workflowViews); 
  console.assert(props.setCurrentView); 
  console.assert(props.currentItem);
  console.assert(props.completionRecord);
  console.assert(props.setCompletionRecord);
  console.assert(props.setInventoryTranscriptUpdated);
 

  const [notes, setNotes] = useState(findItemCompletionTranscriptRecord(props.completionRecord, props.currentItem)?.getNotes());

  /**
   * 
   * @param {*} e 
   */
  const handleChange_notes = (e) => {
    setNotes(e.currentTarget.value);
  }

  /**
   * 
   * @param {*} isPresent 
   */
  const handleClick_itemPresences = (present) => { 
    let ctr = new CompletionTranscriptRecord();
    ctr.setCompletionRecordId(props.completionRecord.getId());
    ctr.setItemId(props.currentItem.getId());
    ctr.setIsContainer(props.currentItem.isContainer());
    ctr.setPresent(present);
    ctr.setNotes(notes); 
   
    // Send the request to the API to update/create the transcript record
    ServiceAPI.CompletionRecord().createInventoryCompletionTranscriptRecord(ctr);

    let existingTranscriptRecord = findItemCompletionTranscriptRecord(props.completionRecord, props.currentItem)
    let updatedCompletionRecord = updateItemsCompletionTranscriptRecord(props.completionRecord, existingTranscriptRecord, ctr);

    props.setInventoryTranscriptUpdated(new Date().getTime()); // This causes the LockerContentView componet to render so that the radio buttons update (See TODO)
    props.setCompletionRecord(updatedCompletionRecord);
    
    // Return the user to the Locker Content view.
    props.setCurrentView(props.workflowViews.LockerContentView);
  }

  /**
   * 
   */
  const renderImageBlock = () => {
    return (
      <Box border={1} borderColor={'lightgrey'} height={'100%'} display={'flex'} flexDirection={'column'} justifyContent={'center'}>
        <Box>
          <ImageOutlinedIcon />
          <h4>Image Unavaliable</h4>
        </Box>
      </Box>
    );
  }


  /**
   * 
   * @returns 
   */
  const render = () => {
    let displayQuantity = props.currentItem.getQuantity ? props.currentItem.getQuantity() + "x" : "";
    
    return (
      <Box marginLeft={1} marginRight={1} display={'flex'} flexDirection={'column'} height={'90%'}>
        <Box>
          <h2>{ displayQuantity } { props.currentItem.getName() }</h2>
          { props.currentItem.getDescription() }
        </Box>

        <Box overflow={'hidden'} flex={1} height={'100%'} padding={'1em'}>
          { renderImageBlock() }
        </Box>

        <Box paddingTop={'1em'}>
          
          <Box marginBottom={'1em'}>
            <TextField
              label="Notes"
              multiline
              defaultValue=""
              variant="outlined"
              style={{width: '95%'}}
              value={ notes }
              onChange={ handleChange_notes }
            />
          </Box>

          <Box display={'flex'} flex={1}>
            <Button
              variant="contained"
              size="large"
              style={{color: 'white', backgroundColor: '#28a745', margin: '.5em', flexGrow: 1}}
              onClick={ () => handleClick_itemPresences("YES") }
            >
              <CheckCircleOutlineIcon />
            </Button>

            <Button
              variant="contained"
              size="large"
              style={{backgroundColor: '#ffc107', margin: '.5em', flexGrow: 1}}
              onClick={ () => handleClick_itemPresences("ISSUE") }
            >
              <ReportProblemOutlinedIcon />
            </Button>

            <Button
              variant="contained"
              size="large"
              style={{color: 'white', backgroundColor: '#dc3545', margin: '.5em', flexGrow: 1}}
              onClick={ () => handleClick_itemPresences("NO") }
            >
              <HighlightOffIcon />
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }

  return render();
}

export default LockerItemView;