import React, { useState, useEffect } from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import { useParams } from 'react-router-dom'

import ServiceAPI from '../../../service/ServiceAPI';
import Styling from './Styling';
import Constants from '../../../utils/Constants';



/**
 * 
 * @param {object} values  (qty, description, present, comment)
 */
const TableRow = (values, styles, depth) => {
  let defaultStyle = [Styling.table.tableCell];
  
  styles = styles ? styles : {};
  styles.qty = styles.qty ? [...styles.qty, ...defaultStyle] : [...defaultStyle];
  styles.description = styles.description ? [...styles.description, ...defaultStyle] : [...defaultStyle];
  styles.present = styles.present ? [...styles.present, ...defaultStyle] : [...defaultStyle];
  styles.comment = styles.comment ? [...styles.comment, ...defaultStyle] : [...defaultStyle];

  let colWidths = {
    qty: 1,
    description: 20,
    present: 2,
    comment: 5
  };

  let indexColStyles = {
    width: `1cm`,
    borderLeftStyle: 'solid',
    borderLeftWidth: '1pt',
    borderLeftColor: 'black',
    paddingLeft: '3pt',
    backgroundColor: 'darkgrey'
  };

  let indentCells = new Array(depth);
  indentCells.fill(
    <View style={[indexColStyles]}>
      <Text> </Text>
    </View>
  );

  return (
    <View style={[Styling.common.row]}>
      { depth > 0 ? indentCells : null }
      
      <View style={[Styling.table.colQty, ...styles.qty, {width: `${colWidths.qty}cm`}]}>
        <Text>{ values.qty ? values.qty : null}</Text>
      </View>
      <View style={[Styling.table.colDescription, ...styles.description, {width: `${colWidths.description - depth}cm`}]}>
        <Text>{ values.description ? values.description : null}</Text>
      </View>
      <View style={[Styling.table.colPresent, ...styles.present, {width: `${colWidths.present}cm`}]}>
        <Text>{ values.present ? values.present : null}</Text>
      </View>
      <View style={[Styling.table.colComments, ...styles.comment, {width: `${colWidths.comment}cm`}]}>
        <Text>{ values.comment ? values.comment : null}</Text>
      </View>
    </View>
  );
}

/**
 * Creates the equipment table header
 */
const TableHeader = () => {
  let headerCellStyles = {
    fontWeight: 'bold',
    color: 'white',
    backgroundColor: 'grey',
    marginTop: '10pt'
  }
  return TableRow(
    {qty: 'Qty', description: 'Description', present: 'Present', comment: 'Comment'},
    {qty: [headerCellStyles], description: [headerCellStyles], present: [headerCellStyles], comment: [headerCellStyles]},
    0
  );
}

/**
 * Returns the JSX for the Completed by section at the end of the inventory
 */
const CompletedBySection = () => {
  let rowStyle = Styling.common.row;
  return (
    <View style={[rowStyle, {marginTop: '10pt', marginBottom: '10pt'}]}>
      <View style={{width: '15cm'}}>
        <Text>Inventory Completed By:</Text>  
      </View>
      <View style={{width: '5cm'}}>
        <Text>Date Completed:</Text>  
      </View>
    </View>
  );
}

/**
 * Create the inventory PDF
 */
const InventoryDocument = (props) => {
  let inventoryRows = [];


  function traverse(container, depth) {
    let levelItems = [];
  
    container.depth = depth;
    levelItems.push(container);
              
    if (container.items?.length > 0) {
      container.items.forEach( (item) => {
        item.depth = depth + 1;
        levelItems.push(item);
      })
    }
  
    if (container.containers.length === 0) {
      return levelItems;
    }
              
    container.containers.forEach( (childContainer) => {
      levelItems.push( ...traverse(childContainer, depth + 1) );
    });
  
    return levelItems;
  }

  // Build the flat array by recursion through the tree (this could be a problem on a large inventory!);
  inventoryRows = traverse(props.data, -1); // -1 reflects the root node, as its depth should not be included, cause we remove it at the next step.
  inventoryRows.shift(); //remove the root inventory node (Eg the inventory name) as it is not equipment
  
  // Sort all the items by display order
  inventoryRows.sort( (item1, item2) => {
    return item1.displayOrder < item2.displayOrder ? -1 : 1;
  });

  let style = [{backgroundColor: 'lightgrey'}]; //Gainsboro';

  
  let styleHeadingContainerDescription = [{
    textDecoration: "underline",
    ...style,
    ...Styling.common.boldText
  }];

  let alternateRowColorStyles = {
    qty: style,  
    description: style, 
    present: style, 
    comment: style
  };

  // Build the TableRowViews
  let inventoryRowViews = inventoryRows.map( (rowItem, index) => {
    let styles = index % 2 === 0 ? null : { ...alternateRowColorStyles };

    if (rowItem.type === Constants.InventoryType.Container && rowItem.heading === 1) {
      if (styles === null) {
        styles = { description: styleHeadingContainerDescription };
      } else {
        styles.description = styleHeadingContainerDescription;
      }
    }

    return TableRow({qty: rowItem?.quantity, description: `${rowItem.name} ${rowItem.description}`}, styles, rowItem?.depth);
  });


  let pages = [];

  do {
    let itemSlice = inventoryRowViews.splice(0, 30);

    pages.push(
      <Page size="A4" style={Styling.common.page} orientation="landscape">
        
        <View fixed style={Styling.common.row}>
          <View style={{width: '80%'}}>
            <Text style={Styling.pageHeader.title}>{ props.data.name }</Text>
          </View>
          <View style={{width: '19%'}}>
            <Text style={Styling.pageHeader.title}>v{ props.data.version }</Text>
          </View>
        </View>

        <View style={Styling.common.col}>
          { TableHeader() }
          { itemSlice }
        </View>

        { inventoryRowViews.length > 0 ? null : CompletedBySection() }

        <Text fixed style={Styling.common.row} render={({ pageNumber, totalPages }) => (
          `Page ${pageNumber} of ${totalPages}`
        )} />
      </Page>
    );
  } while (inventoryRowViews.length > 0);
  
  return (<Document>{ pages }</Document>);
};

/**
 * 
 * @param {*} props 
 * @returns 
 */
const InventoryPdf = (props) => {
  const params = useParams();

  const [inventoryData, setInventoryData] = useState(null);

  /**
   * 
   * @param {*} props 
   */
  const loadInventory = (props) => {
    if (inventoryData !== null) {
      return;
    }

    ServiceAPI.Inventory().read(params.inventoryId).then(
      (data) => {
        setInventoryData(data);
      }
    )
  }

  /**
   * 
   */
  const render = () => {
    if (inventoryData === null) {
      return <h1>Generating...</h1>
    } else {
      return (
        <PDFViewer style={{width: '100%', flex: '2'}}>
          <InventoryDocument data={ inventoryData } />
        </PDFViewer>
      )
    }
  }

  useEffect(() => {
    loadInventory(props);
  }, []);

  return render();
}

export default InventoryPdf;