import ItemService from './ItemService';
import ContainerService from './ContainerService';
import InventoryService from './InventoryService';
import AuthoriseService from './AuthoriseService';
import CompletionRecordService from './CompletionRecordServices';

class ServiceAPI {
  
  Item = () => {
    return new ItemService();
  }

  Container = () => {
    return new ContainerService();
  }
  
  Inventory = () => {
    return new InventoryService();
  }

  Authorise = () => {
    return new AuthoriseService();
  }

  CompletionRecord = () => {
    return new CompletionRecordService();
  }
}

export default new ServiceAPI();
