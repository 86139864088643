import { StyleSheet, Font } from '@react-pdf/renderer';

/**
 * Need to register fonts to allow for Bold to be applied to the fontFamily.
 */
Font.register({
  family: 'Open Sans',
  fonts: [
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 700 }
  ]
})

/**
 * Conmmon styles for the document
 */
const stylesCommon = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#E4E4E4',
    font: 'Verdana',
    fontSize: '11pt',
    margin: '20pt'
  },

  col: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  },
  boldText: {
     fontFamily: 'Open Sans', 
     fontWeight: 'bold', 
  },
});

/**
 * Styles for styling the page header
 */
const stylesHeader = StyleSheet.create({
  image: {
    fontSize: '10pt'
  },
  title: {
    fontSize: '18pt'
  }
});

/**
 * Styles for controling the styling of the inventory equipment table
 */
const stylesTable = StyleSheet.create({
  tableCell: {
    borderColor: 'black',
    borderWidth: '1pt',
    borderStyle: 'solid',
    paddingLeft: '3pt'
  },
  colHeader: {
    fontWeight: 'bold'
  },
  colQty: {
    textAlign: 'center'
  },
  colDescription: {    
  },
  colPresent: {
    textAlign: 'center'
  },
  colComments: {
  }
});

const stylesSections = StyleSheet.create({
  lockerHeader: {
    backgroundColor: 'rgb(184, 204, 228)',
    fontSize: '12pt',
    fontWeight: 'bold'
  }
});

const Styling = () => {
  return {
    pageHeader: stylesHeader,
    common: stylesCommon,
    table: stylesTable,
    sections: stylesSections
  }
}

export default Styling();