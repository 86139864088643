import React, {useState} from 'react';
import { Button, Grid, TextField } from '@material-ui/core';

import ServiceAPI from '../../../service/ServiceAPI';
import Constants from '../../../utils/Constants';

const InventoryCreate = function(props) {
  const [inventoryName, setInventoryName] = useState("");

  const onClick_Create = function() {
    let model = {id: null, name: inventoryName, version: '0.0.0' };

    ServiceAPI.Inventory().createInventory(model).then((data) => {
      props.history.push(Constants.RoutePath.Inventory.Edit(data.id))
    })
    
  };

  const onChange_InventoryName = function(e) {
    setInventoryName(e.target.value)
  }

  return (
    <div className="view">
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={4}
      >
        <Grid item>
          <h1>Create a new Inventory</h1>
        </Grid>
        
        <Grid item>
          <TextField
            id="outlined-name"
            label="Name"
            margin="normal"
            variant="outlined"
            value={inventoryName}
            onChange={onChange_InventoryName}
          />
        </Grid>

        <Grid item>
          <TextField
            id="outlined-read-only-input"
            label="Version"
            defaultValue="0.0.0"
            margin="normal"
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
          />
        </Grid>

        <Grid item>
          <Button variant="contained" color="primary" onClick={onClick_Create}>
            Create
          </Button>
        </Grid>
      </Grid>
    </div>
  );    
}

export default InventoryCreate;