import { useState, useEffect } from 'react';
import { isUserAuthenticated, storeAuth, clearAuth } from '../utils/AuthUtils';

export default function useAuthentication() {
  const [isAuthenticated, setAuthenticated] = useState( isUserAuthenticated() );

  const login = (jwt) => {
    storeAuth(jwt);

    if ( !isUserAuthenticated() ) {
      logout();
    }
      
    setAuthenticated(true); 
  }

  const logout = () => {
    setAuthenticated(false);
    clearAuth();
  }

  return [ isAuthenticated, login, logout ];
}