import Constants from '../utils/Constants';
import ServiceBase from './ServiceBase';
import ContainerModel from '../model/ContainerModel';

const CONTAINER_URL = 'container/';

export default class ContainerService extends ServiceBase {

    /**
     * 
     */
    getContainer = (containerId) => {
      let url = this.getServiceUrl(`${CONTAINER_URL}?${Constants.Http.Param.Id}=${containerId}`);

      let packet = this.buildRequestPacket(Constants.Http.Method.GET);

      return fetch(url, packet).then(this.convertResponseToJson).then(json => {
          return new ContainerModel( json );
      });
    }

    /**
     * 
     */
    createContainer = (container) => {
      let url = this.getServiceUrl(CONTAINER_URL);

      let packet = this.buildRequestPacket(Constants.Http.Method.POST, container);
      
      return fetch(url, packet).then(this.convertResponseToJson).then(json => {
          return json;
      });
    }

    /**
     * 
     */
    updateContainer = (container) => {
      let url = this.getServiceUrl(CONTAINER_URL);

      let packet = this.buildRequestPacket(Constants.Http.Method.PUT, container);

      return fetch(url, packet).then(this.convertResponseToJson).then(json => {
          return json;
      });
    }

    /**
     * 
     */
    deleteContainer = (containerId) => {
      let url = this.getServiceUrl(CONTAINER_URL + "?" + Constants.Http.Param.Id + "=" + containerId);

      let packet = this.buildRequestPacket(Constants.Http.Method.DELETE);

      return fetch(url, packet).then(this.convertResponseToJson).then(json => {
          return json;
      });
    }

    /**
     * Move Container
     */
    moveContainer = (containerId, newParentId) => {
      let url = this.getServiceUrl(CONTAINER_URL) + "move.php";

      let packet = this.buildRequestPacket(Constants.Http.Method.PUT, {id: containerId, newParentId: newParentId});

      return fetch(url, packet).then(this.convertResponseToJson).then(json => {
          return json;
      });
    }
}