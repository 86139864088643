
import Constants from '../utils/Constants'
import ItemModel from './ItemModel'

export default class ContainerModel {

    constructor(data) {

      // TODO: add validation for mandatory items
      let template = {
        id: 0,
        inventoryId: null,
        parentContainerId: null,
        name: "",
        description: "",
        displayOrder: 0,
        sealed: 0,
        heading: 0,
        containers: null,
        items: null,
        type: Constants.InventoryType.Container,
        inventoryDepth: null
      };

      this.data = data ? data : template;

      if (this.data.containers && this.data.containers.length > 0) {
        let converetedContainers = [];
        this.data.containers.forEach((item) => {
            converetedContainers.push(new ContainerModel(item));
        });
        this.data.containers = converetedContainers;
      }

      if (this.data.items && this.data.items.length > 0) {
        let converetedItems = [];
        this.data.items.forEach((item) => {
          converetedItems.push(new ItemModel(item));
        });
        this.data.items = converetedItems;
      }

    }

    flattenContainerContents = () => {
      let flatten = (dataItem, flattened, depth) => {
        dataItem?.setInventoryDepth(depth);

        depth = depth + 1;

        let items = dataItem.getItems();
        items.forEach( (i) => { return i.setInventoryDepth(depth); });

        flattened.push(dataItem);
        flattened.push(...items );
        
        dataItem.getContainers().map( (container) => {
          flatten(container, flattened, depth);
        });

        return flattened;
      } 

      let flattened = []
  
      flattened = flatten(this, flattened, -1);

      flattened = flattened.sort( (a, b) => {
        return a.getDisplayOrder() > b.getDisplayOrder();
      });

      return flattened;
    }

    toJson = () => {
      return JSON.stringify(this.data);
    }

    getId = () => {
        return this.data.id;
    }

    setId = (value) => {
      this.data.id = value;
    }

    getInventoryId = () => {
      return this.data.inventoryId;
    }

    setInventoryId =(value) => {
      this.data.inventoryId = value;
    }

    getParentContainerId = () => {
      return this.data.parentContainerId;
    }

    setParentContainerId = (value) => {
      this.data.parentContainerId = value;
    }

    getDisplayOrder = () => {
      return this.data.displayOrder;
    }
  
    setDisplayOrder = (value) => {
      this.data.displayOrder = value;
    }
    
    getSealed = () => {
      return this.data.sealed;
    }

    setSealed = (value) => {
      this.data.sealed = value;
    }

    getHeading = () => {
      return this.data.heading;
    }

    setHeading = (value) => {
      this.data.heading = value;
    }
    
    getImage = () => {
        return this.data.image ? this.data.image : null;
    }

    getName = () => {
        return this.data.name;
    }

    setName = (value) => {
      this.data.name = value;
    }

    getDescription = () => {
      return this.data.description;
    }

    setDescription = (value) => {
      this.data.description= value;
    }

    getContainers = () => {
        return this.data.containers;
    }

    getItems = () => {
        return this.data.items?.length ? this.data.items : [];
    }

    getType = () => {
      return this.data.type;
    }

    isContainer = () => {
      return true;
    }

    getInventoryDepth = () => {
      return this.data.inventoryDepth;
    }

    setInventoryDepth = (value) => {
      this.data.inventoryDepth = value;
    }
}