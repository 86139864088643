import Constants from '../utils/Constants';
import ServiceBase from './ServiceBase';

const INVENTORY = 'inventory/';

export default class InventoryService extends ServiceBase {

    getListing = () => {
      let url = this.getServiceUrl(INVENTORY);

      let packet = this.buildRequestPacket(Constants.Http.Method.GET);

      return fetch(url, packet).then(this.convertResponseToJson).then(json => {
        return json;
      });
    }

    read = (id) => {
      let url = this.getServiceUrl(INVENTORY + "?" + Constants.Http.Param.Id + "=" + id);

      let packet = this.buildRequestPacket(Constants.Http.Method.GET);

      return fetch(url, packet).then(this.convertResponseToJson).then(json => {
        console.log(json);
        return json;
      });
    }

    createInventory = (model) => {
      let url = this.getServiceUrl(INVENTORY); 

      let packet = this.buildRequestPacket(Constants.Http.Method.POST, model);
  
      return fetch(url, packet).then(this.convertResponseToJson).then(json => {
        return json;
      });
    }

    updateInventory = (model) => {
      let url = this.getServiceUrl(INVENTORY); 

      let packet = this.buildRequestPacket(Constants.Http.Method.PUT, model);
  
      return fetch(url, packet).then(this.convertResponseToJson).then(json => {
        return json;
      });
    }
}