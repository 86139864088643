const { default: CompletionRecord } = require("../../../model/CompletionRecord");
const { default: CompletionTranscriptRecord } = require("../../../model/CompletionTranscriptRecord");
const { default: ContainerModel } = require("../../../model/ContainerModel");
const { default: ItemModel } = require("../../../model/ItemModel");

/**
 * Finds the Completion Transcript Record for a provided invotory 
 * item (either Item or Container) from the Completion Record.
 * 
 * @param {CompletionRecord} completionRecord 
 * @param {ContainerModel | ItemModel} item 
 * @returns {CompletionTranscriptRecord} The CompletionTranscriptRecord object or null if its not found
 */
export const findItemCompletionTranscriptRecord = (completionRecord, item) => {
  let result = completionRecord.getTranscripts().filter( 
    (t) => {
      // Need to check for if it is a container, as items and containers are stored
      // in different tables they can have the same ID value and result in he wrong
      // Completion Transctip Record being returned.
      return t.getItemId() === item.getId() && t.isContainer() == item.isContainer();
    }
  );

  return result.length > 0 ? result[0] : null;
}

/**
 * Finds the existing transcript record and updates it with the new date, or if there
 * is none, pushes a new one into the list.
 * @param {*} completionRecord 
 * @param {*} existingCompletionTranscriptRecord 
 * @param {*} newCompletionTranscriptRecord 
 * @returns 
 */
export const updateItemsCompletionTranscriptRecord = (completionRecord, existingCompletionTranscriptRecord, newCompletionTranscriptRecord) => {

  if (existingCompletionTranscriptRecord === null) {
    // Newly marked in item, no existing transcript exists. Push the created one 
    // from above into the list.
    completionRecord.getTranscripts().push(newCompletionTranscriptRecord);
  } else {
    let updatedTranscript = existingCompletionTranscriptRecord;
    let indexToInsert = completionRecord.getTranscripts().indexOf(updatedTranscript);
    updatedTranscript.setPresent(newCompletionTranscriptRecord.getPresent());
    updatedTranscript.setNotes(newCompletionTranscriptRecord.getNotes());
    completionRecord.getTranscripts()[indexToInsert] = updatedTranscript;
  }

  return completionRecord;
}