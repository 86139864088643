import Constants from '../utils/Constants';
import ServiceBase from './ServiceBase';
//import ContainerModel from '../model/ContainerModel';

const ITEM_URL = 'item/';

export default class ItemService extends ServiceBase {

    updateItem = (item) => {
      let url = this.getServiceUrl(ITEM_URL);

      let packet = this.buildRequestPacket(Constants.Http.Method.PUT, item);

      return fetch(url, packet).then(this.convertResponseToJson).then(json => {
        return json;
      });
    }

    /**
     * 
     */
    createItem = (item) => {
      let url = this.getServiceUrl(ITEM_URL);

      let packet = this.buildRequestPacket(Constants.Http.Method.POST, item);

      return fetch(url, packet).then(this.convertResponseToJson).then(json => {
          return json;
      });
    }

    /**
     * 
     */
    deleteItem = (itemId) => {
      let url = this.getServiceUrl(ITEM_URL + "?" + Constants.Http.Param.Id + "=" + itemId);

      let packet = this.buildRequestPacket(Constants.Http.Method.DELETE);

      return fetch(url, packet).then(this.convertResponseToJson).then(json => {
        return json;
      });
    }

}