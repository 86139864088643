import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const InventoryInputFields = function(props) {

  const render = () => {
    return (
      <div>
        <div>
          { props.handleOnNewContainer ? <Button variant="contained" onClick={ props.handleOnNewContainer }>New Locker</Button> : null }
        </div>

        <div>

          <TextField 
            label="Name" 
            variant="outlined" 
            value={ props.itemName } 
            onChange={ props.handleOnChange } 
            onBlur={ props.handleOnBlur } 
            inputProps={ {'data-field': "name" }} 
            margin="normal"
            fullWidth
          />

          <TextField 
            label="Version" 
            variant="outlined" 
            value={ props.itemVersion } 
            onChange={ props.handleOnChange } 
            onBlur={ props.handleOnBlur } 
            inputProps={ {'data-field': "version" }} 
            margin="normal"
            fullWidth
          />

        </div>
      </div>
    );
  }

  return render();
}

export default InventoryInputFields;
