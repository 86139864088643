export default class CompletionRecord {

  constructor(data) {

      const template = {
        id: 0,
        inventoryId: "",
        description: "",
        started: null,
        completed: null,
        transcripts: null
      };

      // TODO: add validation for mandatory items
      this.data = data ? data : template;
  }

  toJson = () => {
    return JSON.stringify(this.data);
  }

  getId = () => {
      return this.data.id;
  }

  setId = (value) => {
    this.data.id = value;
  }

  getInventoryId = () => {
    return this.data.inventoryId;
  }

  setInventoryId = (value) => {
    this.data.inventoryId = value;
  }

  getDescription = () => {
    return this.data.description;
  }

  setDescription = (value) => {
    this.data.description = value;
  }

  getStarted = () => {
    return this.data.started;
  }

  setStarted = (value) => {
    this.data.started = value;
  }

  getCompleted = () => {
    return this.data.completed;
  }

  setCompleted = (value) => {
    this.data.completed = value;
  } 

  getTranscripts = () => {
    return this.data.transcripts;
  }

  setTranscripts = (value) => {
    this.data.transcripts = value; 
  }
}